import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToolsBoxes = ({ id = "toolBoxSection" }) => {
  const { register, handleSubmit, reset, errors } = useForm();
  const onSubmit = data => {
    const method = "POST";
    const body = JSON.stringify(data);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch("/reserva", {
      method,
      headers,
      body
    }).then(res => {
      if (res.status === 200) {
        reset();
      } else {
        //TODO display error
      }
    }).catch((err) => {
      //TODO display unknown error
    });
  }

  return (
    <section id={id} className="tools-section">
      <div className="container">
        <div className="columns is-boxed">
          <div className="column is-narrow-desktop">
            <div className="opening-hours tool-box">
              <h2 className="title-tool">Horarios Atención</h2>
              <div className="content">
                <ul type="1">
                  <li>Lunes: 8:00 AM – 6:00 PM</li>
                  <li>Martes: 8:00 AM – 6:00 PM</li>
                  <li>Miercoles: 8:00 AM – 6:00 PM</li>
                  <li>Jueves: 8:00 AM – 6:00 PM</li>
                  <li>Viernes: 8:00 AM – 6:00 PM </li>
                  <li>Sabado: Previa cita</li>
                  <li>Domingo: Cerrado</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="emergency tool-box">
              <h2 className="title-tool">Emergencia</h2>
              <div className="content">
                <p>
                  Puedes generar tu cita por medio de Whatsapp:
                <br />
                  <a
                    className="button is-medium is-rounded is-bg-gradient"
                    href="https://wa.me/50245808745"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Has tu cita en whatsapp +50245808745"
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={["fab", "whatsapp"]} />
                    </span>
                    <span>+502.4580.8745</span>
                  </a>
                </p>
                <p>
                  Para consulta al teléfono:
                <br />
                  <a
                    className="button is-medium is-rounded is-bg-gradient"
                    href="tel:+50232265174"
                    data-content="+50232265174"
                    data-type="phone"
                    title="Para consultas llama a +50232265174"
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon="phone-volume" />
                    </span>
                    <span>+502.3226.5174</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="appointment tool-box">
              <h2 className="title-tool">Reserva en linea</h2>
              <form id="appointmentForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          className="input is-medium"
                          type="text"
                          placeholder="Nombre Completo *"
                          ref={register({ required: true })}
                        />
                        {errors.name && <span className="error">Campo requerido</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="email"
                          className="input is-medium"
                          type="text"
                          placeholder="Correo electrónico *"
                          ref={register({ required: true })}
                        />
                        {errors.email && <span className="error">Campo requerido</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="phone"
                          className="input is-medium"
                          type="number"
                          placeholder="Teléfono *"
                          ref={register({ required: true })}
                        />
                        {errors.phone && <span className="error">Campo requerido</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field is-grouped is-grouped-multiline">
                      <div className="control is-expanded">
                        <input
                          name="date"
                          className="input is-medium"
                          type="date"
                          placeholder="Fecha *"
                          title="Fecha"
                          ref={register({ required: true })}
                        />
                        {errors.date && <span className="error">Campo requerido</span>}
                      </div>
                      <p className="control">
                        <button className="button is-medium is-fullwidth is-bg-gradient">
                          <span>Enviar</span>
                          <span className="icon">
                            <FontAwesomeIcon icon="chevron-circle-right" />
                          </span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ToolsBoxes
