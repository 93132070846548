import React from "react"
import doctor from "../../images/drone.png"

const Landing = ({ id = "firstSection" }) => (
  <section id={id} className="init-section">
    <div className="main-hero container">
      <div className="columns is-gapless">
        <div className="column">
          <div className="hero">
            <div className="hero-body">
              <h1 className="title has-text-primary is-size-1 is-size-3-touch">
                Dr. Mario Gutierrez
              </h1>
              <h2 className="subtitle is-size-5">
                Cirujano Oftalmólogo Sub-Especialidad Retina y<br />
                Segmento Anterior
              </h2>
            </div>
          </div>
        </div>
        <div className="column">
          <figure className="image">
            <img src={doctor} alt="Dr. Mario Gutierrez Landing" />
          </figure>
        </div>
      </div>
    </div>
  </section>
)

export default Landing
