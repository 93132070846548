import React, { useState } from "react"
import { AsideOption, AsideOptionCard } from "../AsideOptionTab"
import surgery from "../../images/surgery.jpg"
import glasses from "../../images/glasses.jpg"
import technology from "../../images/technology.jpg"
import social from "../../images/social.png"

const Services = ({ id = "servicesSection" }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <section id={id} className="services-section section bg-blob2">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="content">
              <p className="subtitle is-size-5">
                <strong className="two-tones">Servicios Médicos</strong>
              </p>
              <h2 className="title has-text-primary is-size-1 is-size-3-touch">
                La salud visual es lo primero
              </h2>
            </div>
            <div className="columns  is-vcentered">
              <div className="column is-hidden-touch">
                <AsideOption
                  tabId={0}
                  title=" Cirugía de Retina Mínimamente Invasiva y Catarata Avanzada"
                  subTitle="El cuidado que usted se merece"
                  avatar="user-md"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <AsideOption
                  tabId={1}
                  title="Examen de la Vista"
                  subTitle="Mantengamos su salud visual"
                  avatar="glasses"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <AsideOption
                  tabId={2}
                  title="Tecnología de Última Generación"
                  subTitle="Su vista es mi prioridad"
                  avatar="microscope"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <AsideOption
                  tabId={3}
                  title="Responsabilidad Social"
                  subTitle="Su vista es mi prioridad"
                  avatar="hand-holding-medical"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
              <div className="column">
                <AsideOptionCard
                  tabId={0}
                  title=" Cirugía de Retina Mínimamente Invasiva y Catarata Avanzada"
                  subTitle="El cuidado que usted se merece"
                  avatar="user-md"
                  image={surgery}
                  description="Es una de las principales razones por la que los pacientes acuden a mi consulta.  Cada servicio que ofrezco es personalizado y adecuado a tus necesidades. Soy un profesional de la medicina comprometido con los principios de confianza e integridad médica en el ejercicio de su profesión."
                  activeTab={activeTab}
                />
                <AsideOptionCard
                  tabId={1}
                  title="Examen de la Vista"
                  subTitle="Mantengamos su salud visual"
                  avatar="glasses"
                  image={glasses}
                  description="Para mí nada es más importante que su bienestar y su visión, el principal motivo por el que nunca he dejado de formarme en diversos aspectos de mi especialidad. Si necesita una consulta, haga clic en el siguiente enlace."
                  activeTab={activeTab}
                />
                <AsideOptionCard
                  tabId={2}
                  title="Tecnología de Última Generación"
                  subTitle="Su vista es mi prioridad"
                  avatar="microscope"
                  image={technology}
                  description="Mi enfoque holístico en medicina me ayuda a considerar a cada paciente como si fuera un todo en lugar de solo tratar el síntoma. Utilizando lo más avanzado en tecnología en Guatemala como apoyo diagnóstico y tratamiento. Póngase en contacto hoy mismo para pedir una cita."
                  activeTab={activeTab}
                />
                <AsideOptionCard
                  tabId={3}
                  title="Responsabilidad Social"
                  subTitle="Su vista es mi prioridad"
                  avatar="hand-holding-medical"
                  image={social}
                  description="Las condiciones de pobreza y falta de acceso a la salud visual en Guatemala hace que se tengan alta incidencia de ceguera prevenible (ej. catarata), sobre todo en áreas rurales.  Mediante el desarrollo de programas de prevención y tratamiento, a través de jornadas oftalmológicas médico-quirúrgicos en comunidades rurales de Guatemala que carecen de acceso a salud visual."
                  activeTab={activeTab}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
