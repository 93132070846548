import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactUs = ({ id = "contactusSection" }) => {
  const { register, handleSubmit, reset, errors } = useForm();
  const onSubmit = data => {
    const method = "POST";
    const body = JSON.stringify(data);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch("/send-mail", {
      method,
      headers,
      body
    }).then(res => {
      if (res.status === 200) {
        reset();
      } else {
        //TODO display error
      }
    }).catch((err) => {
      //TODO display unknown error
    });
  }

  return (
    <section id={id} className="contactus-section">
      <div className="container">
        <div className="tile is-ancestor is-marginless">
          <div className="tile is-parent">
            <div className="tile is-chlid">
              <div className="content is-fullwidth has-text-centered section no-pb">
                <p className="subtitle is-size-5">
                  <strong className="two-tones">Contactame</strong>
                </p>
                <h2 className="title has-text-primary is-size-1 is-size-3-touch">
                  ¿Tienes alguna consulta?
              </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="tile is-ancestor is-marginless">
          <div className="tile is-child">
            <figure className="image cover">
              <iframe
                title="Consultorio GPS Marker"
                className="is-overlay"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1930.620746936162!2d-90.51968574340762!3d14.585310539812175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s10%20calle%202-45%20zona%2014%20Edificio%20Clinicas%20Las%20Am%C3%A9ricas%2C%20Consultorio%201103%2C%20Nivel%2011!5e0!3m2!1sen!2sus!4v1589858261187!5m2!1sen!2sus"
                width="100%"
                height="100%"
                frameBorder={0}
                style={{ border: "0" }}
                allowFullScreen={true}
                aria-hidden="false"
              ></iframe>
            </figure>
          </div>
          <div className="tile is-child is-vertical">
            <div className="content">
              <form id="contactusForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          className="input is-medium"
                          type="text"
                          placeholder="Nombre Completo *"
                          ref={register({ required: true })}
                        />
                        {errors.name && <span className="error">Campo requerido</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="email"
                          className="input is-medium"
                          type="text"
                          placeholder="Correo electrónico *"
                          ref={register({ required: true })}
                        />
                        {errors.email && <span className="error">Campo requerido</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="phone"
                          className="input is-medium"
                          type="number"
                          placeholder="Teléfono *"
                          ref={register({ required: true })}
                        />
                        {errors.phone && <span className="error">Campo requerido</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <textarea
                            name="message"
                            className="textarea is-medium"
                            placeholder="Mensaje *"
                            ref={register({ required: true })}
                          ></textarea>
                          {errors.message  && <span className="error">Campo requerido</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                        <button className="button is-medium is-rounded is-bg-gradient">
                          <span>Enviar</span>
                          <span className="icon">
                            <FontAwesomeIcon icon="chevron-circle-right" />
                          </span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
