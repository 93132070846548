import React from "react"

const SeparetorMap = () => (
  <section className="separetor-map-section hero is-medium">
    <div className="hero-body">
      <div className="container">
        <div className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading is-uppercase is-size-4">Paises</p>
              <p className="title is-size-2">20+</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading is-uppercase is-size-4">Cirugías</p>
              <p className="title is-size-2">3K+</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading is-uppercase is-size-4">Pacientes</p>
              <p className="title is-size-2">10K+</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SeparetorMap
