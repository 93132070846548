import React from "react"
import { Link } from "gatsby"
import doctor from "../../images/dtwo.png"

const About = ({ id = "aboutSection" }) => (
  <section id={id} className="about-section section">
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="columns is-vcentered">
            <div className="column is-hidden-touch">
              <figure className="image">
                <img src={doctor} alt="About Dr. Mario Gutierrez" />
              </figure>
            </div>
            <div className="column">
              <div className="content">
                <p className="subtitle is-size-5">
                  <strong className="two-tones">Acerca de mi</strong>
                </p>
                <h2 className="title has-text-primary is-size-1 is-size-3-touch">
                  El especialista que necesitas
                </h2>
                <p>
                  El Dr. Mario Gutiérrez Paz médico y cirujano oftalmólogo,
                  especialista en cirugía de catarata, córnea, cirugía
                  refractiva, queratocono, trauma ocular y retina – vítreo –
                  mácula, en Ciudad de Guatemala. Se gradúa de Médico y Cirujano
                  en la Facultad de Medicina de la Universidad de San Carlos de
                  Guatemala (USAC). Posteriormente, realiza el Posgrado de
                  Oftalmología y la Subespecialidad de Segmento Anterior en la
                  Asociación Instituto Panamericano Contra la Ceguera en
                  Guatemala (IPC). A continuación, concluye los estudios de
                  Posgrado de Alta Especialidad en Enfermedades y Cirugía de
                  Retina, Mácula y Vítreo, así como el Diplomado en
                  Investigación en Oftalmología en el Instituto de Oftalmología
                  Conde de Valenciana y la Asociación Para Evitar la Ceguera en
                  México, ambos programas académicos avalados por la Universidad
                  Nacional Autónoma de México (UNAM).
                </p>
                <p>
                  Es también Miembro Activo de Asociaciones Internacionales en
                  Oftalmología y de Alta Especialidad en Retina, y forma parte
                  del Grupo de Ojos y Visión del Centro Cochrane como Autor de
                  Revisiones sistemáticas y meta-análisis. Cuenta con
                  acreditación del Centro de Lectura Duke (DukeReadingCenter)
                  para toma de imágenes de tomografía de coherencia óptica (OCT)
                  con Spectralis Heidelberg.
                </p>

                <Link
                  className="button is-large is-rounded is-bg-gradient"
                  to="/about-me/"
                  title="Conoce mas sobre Dr Mario Gutierrez Oftalmologo en Guatemala / Cirugía Ojos / Visión"
                >
                  <span>Leer mas</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default About
