import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AsideOptionCard = ({
  tabId,
  title,
  subTitle,
  avatar,
  image,
  description,
  activeTab,
}) => (
  <article
    className={`card aside-option-card is-hidden-desktop ${
      activeTab === tabId ? "active" : ""
    }`}
  >
    <div className="card-image">
      <figure className="image is-4by3">
        <img
          src={image}
          alt="Cirugía de Retina Mínimamente Invasiva y Catarata
            Avanzada"
        />
      </figure>
    </div>
    <div className="card-content">
      <div className="media vcentered-media is-hidden-desktop">
        <figure className="media-left">
          <div className="avatar">
            <span className="icon is-large has-text-info">
              <FontAwesomeIcon icon={avatar} size="2x" />
            </span>
          </div>
        </figure>
        <div className="media-content">
          <div className="content is-small">
            <p className="is-size-6 has-text-primary has-text-weight-medium	has-small-space">
              {title}
            </p>
          </div>
        </div>
      </div>
      <div className="content">
        <p className="sub-text has-text-centered has-light-blue-text has-small-space is-hidden-desktop">
          {subTitle}
        </p>
        <p className="has-text-centered-touch">{description}</p>
      </div>
    </div>
  </article>
)

export default AsideOptionCard
