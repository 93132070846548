import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AsideOption = ({
  tabId,
  title,
  subTitle,
  avatar,
  activeTab,
  setActiveTab,
}) => (
  <article
    className={`aside-option box ${activeTab === tabId ? "active" : ""}`}
    onClick={() => setActiveTab(tabId)}
    role="presentation"
  >
    <div className="media vcentered-media">
      <figure className="media-left">
        <div className="avatar box">
          <span className="icon is-large">
            <FontAwesomeIcon icon={avatar} size="3x" />
          </span>
        </div>
      </figure>
      <div className="media-content">
        <div className="content">
          <p className="title-text">
            <strong className="is-size-4 has-small-space">{title}</strong>
            <br />
            <span className="sub-text">{subTitle}</span>
          </p>
        </div>
      </div>
    </div>
  </article>
)

export default AsideOption
