import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import roble from "../../images/roble.png"
import bam from "../../images/bam.png"
import universales from "../../images/universales.png"
import gandt from "../../images/gandt.png"
import rpn from "../../images/rpn.png"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1023, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

const SeparetorInsurances = () => (
  <section className="separetor-insurance-section">
    <article className="container is-fluid">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={2000}
        draggable
        infinite
        minimumTouchDrag={80}
        responsive={responsive}
        containerClass="carousel-container"
        swipeable
      >
        <div className="carousel-item">
          <figure className="image">
            <img src={roble} alt="Seguros El Roble" />
          </figure>
        </div>
        <div className="carousel-item">
          <figure className="image">
            <img src={bam} alt="Seguros Agromercantil BAM" />
          </figure>
        </div>
        <div className="carousel-item">
          <figure className="image">
            <img src={universales} alt="Seguros Universales" />
          </figure>
        </div>
        <div className="carousel-item">
          <figure className="image">
            <img src={gandt} alt="Seguros GyT" />
          </figure>
        </div>
        <div className="carousel-item">
          <figure className="image">
            <img src={rpn} alt="Red de Proveedores Negociados" />
          </figure>
        </div>
      </Carousel>
    </article>
  </section>
)

export default SeparetorInsurances
