import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Landing,
  ToolsBoxes,
  About,
  SeparetorMap,
  Services,
  SeparetorInsurances,
  ContactUs,
} from "../components/Sections"

import metaImage from "../images/logo_icon.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Dr. Mario Gutierrez" metaImage={metaImage} />
    <Landing />
    <ToolsBoxes />
    <About />
    <SeparetorMap />
    <Services />
    <SeparetorInsurances />
    <ContactUs />
  </Layout>
)

export default IndexPage
